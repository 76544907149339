import { instance, downloadMetricsInstance } from '../index';

/**
 * This is an example of enpoint
 */
const LocaliApi = {
  addLocale(payload) {
    return instance.post('/business-accounts', payload);
  },
  getLocaliMetrics(payload) {
    return instance.get('/places/metrics/data-by-place', { params: payload });
  },
  updateLocaliDates(place_id, payload) {
    return instance.put(`/places/${place_id}/update-places-date`, payload);
  },
  downloadLocaliMetrics() {
    return downloadMetricsInstance.get('/places/metrics/download-data-by-place');
  },

  getLocaleMetrics(place_id) {
    return instance.get(`/places/${place_id}/metrics/general-metrics`);
  },
};

export default LocaliApi;
