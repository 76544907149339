import React from 'react';
import PropType from 'prop-types';

const Separator = ({ direction = 'vertical', space }) => {
  const style = {
    horizontal: { minWidth: `${space}px` },
    vertical: { height: `${space}px` },
  };

  return <div style={{ ...style[direction] }}></div>;
};

Separator.propTypes = {
  direction: PropType.string,
  space: PropType.number,
};

export default Separator;
