import React from 'react';
import PropType from 'prop-types';

import styles from './BaseCheckbox.module.scss';

/**
 * Base component to handle checkbox element
 * @param {Function} onChange - Used to pass the change event handler
 * @param {String} label - Used to pass the checkbox label
 * @param {Boolean} value - Used to set the checkbox as checked or not
 * @returns
 */
const BaseCheckbox = ({ onChange, label, value }) => {
  return (
    <div>
      <input
        defaultChecked={value}
        name={label}
        id={label}
        type='checkbox'
        className={styles['css-checkbox']}
        onChange={onChange}
      />
      <label htmlFor={label}>{label}</label>
    </div>
  );
};

BaseCheckbox.propTypes = {
  onChange: PropType.func,
  label: PropType.string,
  value: PropType.bool,
};

export default BaseCheckbox;
