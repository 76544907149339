import React from 'react';
import PropType from 'prop-types';

import styles from './BaseInput.module.scss';

// Componets
import BaseLabel from '../BaseLabel/BaseLabel';
import Separator from '../Separator/Separator';

// Icons
import { ReactComponent as LensIcon } from '../../../assets/icons/lens.svg';
import { ReactComponent as CrossIcon } from '../../../assets/icons/iconLineCross.svg';

/**
 * Base input component
 * @param {String} className - Used to pass additional css classes
 * @param {Function} onChange - Used to handle the input value change event
 * @param {String} label - Used to show a prefix label above the input
 * @param {String} placeholder - Used to show a placeholder inside the input
 * @param {Boolean} readonly - Used to set the input has readonly or not
 * @param {Boolean} showIcon - Used to set the input prepend icon
 * @param {String} value - Used to set the input value
 * @returns
 */
const BaseInput = ({
  className,
  dateSelected,
  onChange,
  label,
  placeholder,
  readonly,
  removeDates,
  showIcon = false,
  value,
}) => {
  return (
    <React.Fragment>
      {label && (
        <>
          <BaseLabel label={label} /> <Separator space={10} />
        </>
      )}
      <div className={`${styles.wrapper} ${className}`}>
        {showIcon && <LensIcon />}
        <input
          className={styles.input}
          onChange={(event) => onChange(event.target.value)}
          placeholder={placeholder}
          readOnly={readonly}
          value={value}
        />
        {dateSelected && <CrossIcon className={styles.close} onClick={removeDates} />}
      </div>
    </React.Fragment>
  );
};

BaseInput.propTypes = {
  className: PropType.string,
  dateSelected: PropType.bool,
  label: PropType.string,
  onChange: PropType.func,
  placeholder: PropType.string,
  readonly: PropType.bool,
  removeDates: PropType.func,
  showIcon: PropType.bool,
  value: PropType.oneOfType([PropType.number, PropType.string]),
};

export default BaseInput;
