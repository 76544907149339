import React, { useState } from 'react';
import PropType from 'prop-types';

import styles from './AddLocale.module.scss';

import BaseInput from '../../base/BaseInput/BaseInput';
import Separator from '../../base/Separator/Separator';
import BaseButton from '../../base/BaseButton/BaseButton';
import BaseCalendar from '../../base/BaseCalendar/BaseCalendar';
import BaseLabel from '../../base/BaseLabel/BaseLabel';
import AgreementLengthPicker from '../../generics/AgreementLengthPicker/AgreementLengthPicker';

import { toISOLocal } from '../../../utils/dates';
import { DAYS, MONTHS, YEARS } from '../../../utils/contstants';

import LocaliApi from '../../../api/modules/locali';

const AddLocale = ({ closeModal }) => {
  const [agreement, setAgreement] = useState();
  const [date, setDate] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [errorMessage, setErrorMessage] = useState(null)

  const setAgreementTime = ({ time, timeUnit }) => {
    const timeConversion = {
      [DAYS]: time,
      [MONTHS]: time * 31,
      [YEARS]: time * 365,
    };

    setAgreement(timeConversion[timeUnit]);
  };

  const addLocale = async () => {


      const localeResource = await LocaliApi.addLocale({
        name: name,
        email: email,
        place_name: name,
        contract_duration_days: agreement,
        contract_starting_date: new Date(date).getTime(),
      });
  
      if (localeResource.statusCode === 200){
        setDate('')
        closeModal();
      }
      else if (localeResource.statusCode === 400){

        const errors = localeResource.errors;

        if(errors[1] && errors[1] === 'User already exists'){
          setErrorMessage('Questa email è già stata usata nell\'applicazione');
        }else{
          setErrorMessage(errors[1]);
        }
      }
  };

  return (
    <div className={styles.wrapper}>
      <h1>Registrazione nuovo locale</h1>
      <Separator space={20} />
      <BaseInput label='Nome locale' placeholder='Nome locale' onChange={(val) => {setName(val); setErrorMessage(null)}} />
      <Separator space={20} />
      <BaseInput
        label='Email'
        placeholder='esempio@esempio.com'
        onChange={(email) => {setEmail(email); setErrorMessage(null)}}
      />
      <Separator space={20} />
      <BaseLabel label='Inizio contratto' />
      <Separator space={10} />
      <BaseCalendar inline={false} range={false} onChange={(date) => {setDate(toISOLocal(date)); setErrorMessage(null)}} />
      <Separator space={20} />
      <AgreementLengthPicker setAgreementTime={setAgreementTime} />
      <Separator space={20} />
      { errorMessage ? <><p className={styles.wrapper__error}>{errorMessage}</p>
      <Separator space={20} /></> : null}
      <BaseButton
        type={errorMessage !== null || email === '' || name === '' || date === '' ? 'disabled' : 'active'}
        disabled={errorMessage !== null || email === '' || name === '' || date === ''}
        label='Registra locale'
        className={styles.registerButton}
        onClick={addLocale}
      />
    </div>
  );
};

AddLocale.propTypes = {
  closeModal: PropType.func,
};

export default AddLocale;
