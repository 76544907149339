import React from 'react';
import { FilterContextProvider } from '../../context/filter-context';

import { AuthProvider } from '../../context/auth-context';

import Dashboard from '../Dashboard/Dashboard';

const HomePage = () => {
  return (
    <AuthProvider>
      <FilterContextProvider>
        <Dashboard />
      </FilterContextProvider>
    </AuthProvider>
  );
};

export default HomePage;
