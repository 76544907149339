import { formatDate } from './dates';
import moment from 'moment';

export const getLocalStatus = (locali, selected, endDate) => {
  const itemsWithStatus = locali.map((item) => {
    const today = moment();
    let end_date;
    if (!item.contract_ending_date) {
      end_date = moment(item.contract_starting_date).add(item.contract_duration_days, 'days');
    } else {
      end_date = moment(item.contract_ending_date, 'DD/MM/YYYY');
    }

    if (endDate && item.id === selected.id) {
      end_date = moment(endDate);
    }
    const status = end_date.isAfter(today) ? 'active' : 'disactive';
    const contract_ending_date = formatDate(end_date);
    return { ...item, contract_ending_date, status };
  });
  return itemsWithStatus;
};
