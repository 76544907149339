import React from 'react';
import PropType from 'prop-types';
import BaseInput from '../BaseInput/BaseInput';

import styles from './ListHeader.module.scss';
import BaseButton from '../BaseButton/BaseButton';
import Separator from '../Separator/Separator';

const ListHeader = ({
  onDownloadMetrics,
  onMainButtonClick,
  onNameFilterChange,
  placeholder,
  showAddLocale = true,
  title,
}) => {
  return (
    <header className={styles.wrapper}>
      <p className={styles.title}>{title}</p>
      <div className={styles.actions}>
        <BaseInput placeholder={placeholder} showIcon onChange={onNameFilterChange} />
        <Separator direction='horizontal' space={10} />
        <BaseButton
          icon='download'
          label='Scarica Excel dati'
          className={styles.button}
          onClick={onDownloadMetrics}
        />
        {showAddLocale ? (
          <React.Fragment>
            <Separator direction='horizontal' space={10} />
            <BaseButton
              icon='plus'
              label='Aggiungi locale'
              type='active'
              onClick={onMainButtonClick}
              className={styles.button}
            />
          </React.Fragment>
        ) : null}
      </div>
    </header>
  );
};

ListHeader.propTypes = {
  onDownloadMetrics: PropType.func,
  onMainButtonClick: PropType.func,
  onNameFilterChange: PropType.func,
  placeholder: PropType.string,
  showAddLocale: PropType.bool,
  showDownloadButton: PropType.bool,
  title: PropType.string,
};

export default ListHeader;
