import React, { useContext, useState } from 'react';
import { FilterContext } from '../../../context/filter-context';

import styles from './CustomerFilters.module.scss';

import AgeFilter from '../AgeFilter/AgeFilter';
import BaseButton from '../../base/BaseButton/BaseButton';
import CalendarFilter from '../CalendarFilter/CalendarFilter';
import CheckboxesFilter from '../CheckboxesFilter/CheckboxesFilter';
import Separator from '../../base/Separator/Separator';
import ActivePlaceFilter from '../ActivePlaceFilter/ActivePlaceFilter';

import FilterToggle from '../FilterToggle/FilterToggle';

import {
  genderOptions,
  orientationOptions,
  profilesOptions,
} from '../../../utils/user-profile-options';

import { interests } from '../../../utils/interests';

const CustomerFilters = () => {
  // We store here the filter while user is selecting them, after the users clicks the confirm button we update the context
  const [ageFilter, setAgeFilter] = useState();
  const [interessiFilter, setInteressiFilter] = useState();
  const [profiliFilter, setProfiliFilter] = useState();
  const [orientamentoFilter, setOrientamentoFilter] = useState();
  const [sessoFilter, setSessoFilter] = useState();
  const [selectedFilter, setSelectedFilter] = useState();

  // We use context to store filters to be used then for api call payload
  const filterContext = useContext(FilterContext);
  const {
    age,
    interessi,
    orientamento,
    profili,
    showBlocked,
    sesso,
    setAge,
    setInteressi,
    setOrientamento,
    setProfili,
    setSesso,
    setShowBlocked,
  } = filterContext;

  const filters = {
    age: {
      component: <AgeFilter value={age} setAgeFilter={(value) => setAgeFilter(value)} />,
      confirmAction: () => {
        setAge(ageFilter);
        setSelectedFilter(null);
      },
    },
    interessi: {
      component: (
        <CheckboxesFilter
          items={interests}
          selected={interessiFilter}
          onSelectionChange={(value) => setInteressiFilter(value)}
          title='Interessi'
        />
      ),
      confirmAction: () => {
        setInteressi(interessiFilter);
        setSelectedFilter(null);
      },
    },
    orientamento: {
      component: (
        <CheckboxesFilter
          items={orientationOptions}
          selected={orientamentoFilter}
          onSelectionChange={(value) => setOrientamentoFilter(value)}
          title='Orientamento'
        />
      ),
      confirmAction: () => {
        setOrientamento(orientamentoFilter);
        setSelectedFilter(null);
      },
    },
    profiliMostrati: {
      component: (
        <CheckboxesFilter
          items={profilesOptions}
          selected={profiliFilter}
          onSelectionChange={(value) => setProfiliFilter(value)}
          title='Profili mostrati'
        />
      ),
      confirmAction: () => {
        setProfili(profiliFilter);
        setSelectedFilter(null);
      },
    },
    sesso: {
      component: (
        <CheckboxesFilter
          items={genderOptions}
          selected={sessoFilter}
          onSelectionChange={(value) => setSessoFilter(value)}
          title='Sesso'
        />
      ),
      confirmAction: () => {
        setSesso(sessoFilter);
        setSelectedFilter(null);
      },
    },
  };

  // Retrieving labels
  const genderLabels = {};
  const interessiLabels = {};
  const orientamentoLabels = {};
  const profiliLabels = {};

  genderOptions.forEach(({ label, value }) => (genderLabels[value] = label));
  interests.forEach(({ label, value }) => (interessiLabels[value] = label));
  orientationOptions.forEach(({ label, value }) => (orientamentoLabels[value] = label));
  profilesOptions.forEach(({ label, value }) => (profiliLabels[value] = label));

  /**
   * Method used to remove one filter selection when clicking the X on the tag.
   * We need to update both the context and the component internal filters
   */
  const handleRemoveItem = ({ item, type }) => {
    const functions = {
      orientamento: {
        contextItems: orientamento ? [...orientamento] : [],
        contextUpdate: (items) => setOrientamento(items),
        filterItems: orientamentoFilter ? [...orientamentoFilter] : orientamentoFilter,
        filterUpdate: (items) => setOrientamentoFilter(items),
      },
      interessi: {
        contextItems: interessi ? [...interessi] : [],
        contextUpdate: (items) => setInteressi(items),
        filterItems: interessiFilter ? [...interessiFilter] : [],
        filterUpdate: (items) => setInteressiFilter(items),
      },
      profiliMostrati: {
        contextItems: profili ? [...profili] : [],
        contextUpdate: (items) => setProfili(items),
        filterItems: profiliFilter ? [...profiliFilter] : [],
        filterUpdate: (items) => setProfiliFilter(items),
      },
      sesso: {
        contextItems: sesso ? [...sesso] : [],
        contextUpdate: (items) => setSesso(items),
        filterItems: sessoFilter ? [...sessoFilter] : [],
        filterUpdate: (items) => setSessoFilter(items),
      },
    };

    const contextIndex = functions[type].contextItems.findIndex((_item) => item === _item);
    const itemIndex = functions[type].filterItems.findIndex((_item) => item === _item);

    functions[type].contextItems.splice(contextIndex, 1);
    functions[type].filterItems.splice(itemIndex, 1);

    functions[type].contextUpdate(functions[type].contextItems);
    functions[type].filterUpdate(functions[type].filterItems);
  };

  return (
    <>
      {selectedFilter ? (
        <div className={styles.overlay}>
          <Separator space={20} />
          {filters[selectedFilter].component}
          <Separator space={40} />
          <BaseButton
            className={styles.button}
            label='Conferma selezione'
            type='confirm'
            onClick={() => filters[selectedFilter].confirmAction()}
          />
        </div>
      ) : (
        <div>
          <Separator space={30} />
          <CalendarFilter />
          <Separator space={30} />

          <FilterToggle
            title='Età'
            items={age}
            setItem={() => setAge([])}
            setSelectedFilter={() => setSelectedFilter('age')}
            showSingleTag
          />
          <Separator space={20} />
          <FilterToggle
            title='Sesso'
            labels={genderLabels}
            items={sesso}
            removeItem={(item) => handleRemoveItem({ item, type: 'sesso' })}
            setSelectedFilter={() => setSelectedFilter('sesso')}
          />
          <Separator space={20} />
          <FilterToggle
            title='Profili mostrati'
            labels={profiliLabels}
            items={profili}
            removeItem={(item) => handleRemoveItem({ item, type: 'profiliMostrati' })}
            setSelectedFilter={() => setSelectedFilter('profiliMostrati')}
          />
          <Separator space={20} />
          <FilterToggle
            title='Orientamento'
            labels={orientamentoLabels}
            items={orientamento}
            removeItem={(item) => handleRemoveItem({ item, type: 'orientamento' })}
            setSelectedFilter={() => setSelectedFilter('orientamento')}
          />

          <Separator space={20} />
          <FilterToggle
            title='Interessi'
            labels={interessiLabels}
            items={interessi}
            removeItem={(item) => handleRemoveItem({ item, type: 'interessi' })}
            setSelectedFilter={() => setSelectedFilter('interessi')}
          />
          <Separator space={20} />
          <ActivePlaceFilter
            label='Mostra utenti bloccati'
            value={showBlocked}
            onChange={() => setShowBlocked(!showBlocked)}
          />
        </div>
      )}
    </>
  );
};

export default CustomerFilters;
