import React from 'react';
import PropType from 'prop-types';
import { Tooltip } from 'react-tooltip';

import styles from './BaseTable.module.scss';
import 'react-tooltip/dist/react-tooltip.css';

import { ReactComponent as SortArrow } from '../../../assets/icons/Freccia.svg';

/**
 * Component to handle table view.
 * @param {Array} headers - List of columns to be shown inside the table. The list must be an array of objects structured as follows
 *   {
      label: COLUMN TITLE,
      valueKey: KEY ON THE ITEMS OBJECT LIST THAT IDENTIFIES THE COLUMN VALUES,
      formatData: FUNCTION TO BE USED TO FORMAT THE COLUMN DATA IN SOME PARTICULAR WAY,
      style: {
        maxWidth: '150px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
 * @returns
 */
const BaseTable = ({ headers, items = [], onItemClick, isLocal }) => {
  return (
    <table className={styles.table}>
      <thead>
        <tr>
          {headers.map((title, index) => {
            return (
              <th
                data-sortable={title.sortable}
                key={index}
                onClick={() =>
                  title.sortable &&
                  title.changeSort({ key: title.valueKey, oldSortDirection: title.sort.direction })
                }
                className={isLocal ? styles.thForLocals : null}
              >
                <div className={styles.title}>
                  {title.label}
                  {title.sortable && <SortArrow data-sort-direction={title.sort.direction} />}
                </div>
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {items.map((item, index) => {
          const keys = headers.map(({ valueKey }) => valueKey);
          return (
            <tr key={index}>
              {keys.map((key) => {
                let data = item[key];
                const { formatData, style } = headers.find(({ valueKey }) => valueKey === key);
                if (formatData) data = formatData(item[key]);
                return (
                  <td key={key} style={style}>
                    <span
                      onClick={
                        key === 'name' || key === 'firstname' ? () => onItemClick(item) : null
                      }
                    >
                      {item.status && item.firstname && key === 'status' ? (
                        <>
                          <div
                            className={`${styles.table__status} ${
                              data === 'none' ? styles.table__active : styles.table__none
                            }`}
                          />
                          <p className={data === 'none' ? styles.active : styles.none}>
                            {data === 'none' ? 'Attivo' : 'Non Attivo'}
                          </p>
                        </>
                      ) : key === 'status' ? (
                        <div>
                          <div
                            className={`${styles.table__status} ${
                              data === 'active' ? styles.table__active : styles.table__none
                            }`}
                          />
                          <p className={data === 'active' ? styles.active : styles.none}>
                            {data === 'active' ? 'Attivo' : 'Non Attivo'}
                          </p>
                        </div>
                      ) : key === 'interest_ids' ? (
                        <>
                          <a
                            data-tooltip-id='my-tooltip'
                            data-tooltip-content={data}
                            style={{ cursor: 'pointer' }}
                          >
                            {data}
                          </a>
                          <Tooltip id='my-tooltip' place='top' />
                        </>
                      ) : (
                        data
                      )}
                    </span>
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

BaseTable.propTypes = {
  headers: PropType.array,
  items: PropType.array,
  onItemClick: PropType.func,
  isLocal: PropType.bool,
};

export default BaseTable;
