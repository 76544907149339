import React from 'react';
import PropType from 'prop-types';

import styles from './BaseModal.module.scss';

import { ReactComponent as CrossIcon } from '../../../assets/icons/iconLineCross.svg';

/**
 * Base component to handle a modal shown
 * @param {Element} children - The nested components to be shown inside the modal
 * @param {Function} closeModal - Use to handle the close modal event
 * @param {Boolean} showModal - Use to show or hide the modal (its value could be true or false)
 * @returns
 */
const BaseModal = ({ children, closeModal, showModal }) => {
  if (showModal) {
    return (
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <CrossIcon className={styles.close} onClick={closeModal} />
          {children}
        </div>
      </div>
    );
  }

  return null;
};

BaseModal.propTypes = {
  children: PropType.element,
  closeModal: PropType.func,
  showModal: PropType.bool,
};

export default BaseModal;
