import axios from 'axios';
import { getCookie } from '../utils/cookies';

/**
 * Create axios instance
 * Base url should be saved in .env
 * // TODO: add interceptor if jwt token is needed
 */
const instance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/v1`,
});

// Setting bearer from cookie before every api call
instance.interceptors.request.use((config) => {
  const jwt = getCookie('jwt');
  config.headers.Authorization = `Bearer ${jwt}`;
  return config;
});

// Intercepting every response and formatting it for FE
instance.interceptors.response.use(
  (response) => {
    const formattedResponse = {
      ...response.data,
    };
    return formattedResponse;
  },
  (error) => {
    const { status = {}, data = {} } = error.response;
    return { statusCode: status, errors: data.errors || [] };
  },
);

const downloadMetricsInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/v1`,
  responseType: 'blob',
});

// Setting bearer from cookie before every api call
downloadMetricsInstance.interceptors.request.use((config) => {
  const jwt = getCookie('jwt');
  config.headers.Authorization = `Bearer ${jwt}`;
  return config;
});

downloadMetricsInstance.interceptors.response.use(
  (response) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(response.data);
    return new Promise((resolve) => {
      reader.onload = () => {
        const arrayBuffer = reader.result;
        const blob = new Blob([arrayBuffer], { type: 'application/octet-stream' });
        const href = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'file.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        resolve(response);
      };
    });
  },
  (error) => {
    const { status = {} } = error.response;

    return { statusCode: status };
  },
);

export { instance, downloadMetricsInstance };
