import React from 'react';

import PropType from 'prop-types';

import styles from './BaseLabel.module.scss';

/**
 * Base component to show a label
 * @param {String} label - Text to be shown
 * @returns
 */
const BaseLabel = ({ label }) => {
  return <p className={styles.label}>{label}:</p>;
};

BaseLabel.propTypes = {
  label: PropType.string,
};

export default BaseLabel;
