export const interests = [
  {
    label: 'Sport',
    value: 1,
  },
  {
    label: 'Musica',
    value: 2,
  },
  {
    label: 'Cucina',
    value: 3,
  },
  {
    label: 'Libri',
    value: 4,
  },
  {
    label: 'Animali',
    value: 5,
  },
  {
    label: 'Film/Serie TV',
    value: 6,
  },
  {
    label: 'Viaggiare',
    value: 7,
  },
  {
    label: 'Escursionismo',
    value: 8,
  },
  {
    label: 'Cibo',
    value: 9,
  },
  {
    label: 'Ballare',
    value: 10,
  },
  {
    label: 'Serata con gli amici',
    value: 11,
  },
  {
    label: 'Palestra',
    value: 12,
  },
  {
    label: 'Scrivere',
    value: 13,
  },
  {
    label: 'Arte',
    value: 14,
  },
  {
    label: 'Giardinaggio',
    value: 15,
  },
  {
    label: 'Bricolage',
    value: 16,
  },
  {
    label: 'Videogiochi',
    value: 17,
  },
  {
    label: 'Yoga',
    value: 18,
  },
  {
    label: 'Volontariato',
    value: 19,
  },
  {
    label: 'Ascoltare podcast',
    value: 20,
  },
  {
    label: 'Fare shopping',
    value: 21,
  },
];
