import moment from 'moment';
export const toISOLocal = (date) => {
  var z = (n) => ('0' + n).slice(-2);
  var zz = (n) => ('00' + n).slice(-3);

  return `${date.getFullYear()}-${z(date.getMonth() + 1)}-${z(date.getDate())}T${z(
    date.getHours(),
  )}:${z(date.getMinutes())}:${z(date.getSeconds())}.${zz(date.getMilliseconds())}Z`;
};

/**
 * Format date to dd/mm/yyyy
 */
export const formatDate = (dateString) => {
  const date = moment(dateString);
  const day = date.date().toString().padStart(2, '0');
  const month = (date.month() + 1).toString().padStart(2, '0');
  const year = date.year();
  return `${day}/${month}/${year}`;
};

export const getEndofContract = (startingDate, durationDays) => {
  const date = new Date(startingDate);
  const timestamp = date.getTime();
  const dayInMilliseconds = 24 * 60 * 60 * 1000;
  const endTimestamp = timestamp + durationDays * dayInMilliseconds;

  const endDate = new Date(endTimestamp);

  const day = endDate.getDate().toString().padStart(2, '0');
  const month = (endDate.getMonth() + 1).toString().padStart(2, '0');
  const year = endDate.getFullYear();
  return `${day}/${month}/${year}`;
};

export const getTodayDate = () => {
  const currentDate = new Date();
  const day = currentDate.getDate().toString().padStart(2, '0');
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  const year = currentDate.getFullYear();
  const formattedDate = `${day}/${month}/${year}`;
  return { formattedDate };
};

export const getContractDurationDays = (item) => {
  const startDate = item.contract_starting_date;
  const [day, month, year] = item.split('/');
  const isoDate = `${year}-${month}-${day}T00:00:00.000Z`;
  const diffTime = new Date(isoDate) - new Date(startDate);
  const durationDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return { durationDays };
};
