import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
// import axios from 'axios';

// Components
import AddLocale from '../../components/modals/AddLocale/AddLocale';
import BaseModal from '../../components/base/BaseModal/BaseModal';
import BaseTable from '../../components/base/BaseTable/BaseTable';
import ListHeader from '../../components/base/ListHeader/ListHeader';
import LocaleDetails from '../../components/modals/LocaleDetails/LocaleDetails';
import Separator from '../../components/base/Separator/Separator';

// Utils
// import { getCookie } from '../../utils/cookies';
import { getLocalStatus } from '../../utils/status';
import { getTodayDate, formatDate } from '../../utils/dates';

// API
import LocaliApi from '../../api/modules/locali';

// Context
import { FilterContext } from '../../context/filter-context';

const Locali = () => {
  const [items, setItems] = useState([]);
  const [nameFilter, setNameFilter] = useState();
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [showDetailModal, setShowDetailModal] = useState(false);

  const filterContext = useContext(FilterContext);
  const { dates, localiSort, setLocaliSort, isInactive, setContractEndDate } = filterContext;
  const headers = [
    {
      label: 'Locale',
      valueKey: 'name',
      style: {
        flex: '1',
      },
    },
    {
      label: 'Status',
      valueKey: 'status',
      style: {
        width: '155px',
      },
    },
    {
      label: 'QR Code',
      valueKey: 'qr_codes_number',
      style: {
        width: '130px',
      },
    },
    {
      label: 'Utenti registrati',
      valueKey: 'checkins_number',
      sortable: true,
      sort: localiSort,
      changeSort: ({ key, oldSortDirection }) => {
        setLocaliSort({ key, direction: oldSortDirection === 'desc' ? 'asc' : 'desc' });
      },
      style: {
        width: '200px',
      },
    },
  ];

  const retrieveLocali = async (endDate) => {
    const localiResource = await LocaliApi.getLocaliMetrics();

    const itemsWithStatus = getLocalStatus(localiResource.data, selectedItem, endDate);
    setContractEndDate();

    const activeItems = itemsWithStatus.filter((item) => item.status === 'active');
    const inactiveItems = itemsWithStatus.filter((item) => item.status !== 'active');
    const allItems = activeItems.concat(inactiveItems);

    if (!isInactive) setItems(activeItems);
    else setItems(allItems);
  };

  const handleNameFilterChange = (value) => {
    setNameFilter(value);
  };

  const filterItems = (items) => {
    if (nameFilter) {
      items = items.filter(
        ({ name }) => name.toLowerCase().indexOf(nameFilter.toLowerCase()) !== -1,
      );
    }

    if (dates?.length > 0) {
      const activeItems = [];

      for (let i = 0; i < items.length; i++) {
        const item = items[i];

        const endingDate = moment(item.contract_ending_date, 'DD/MM/YYYY');
        const endDate = moment(formatDate(dates[1], 'DD/MM/YYYY'));
        if (endingDate.isAfter(endDate)) {
          activeItems.push(item);
        }
      }

      return activeItems;
    }

    const { key, direction } = localiSort;
    items.sort((a, b) => {
      const keyA = a[key];
      const keyB = b[key];

      if (keyA < keyB) return direction === 'asc' ? -1 : 1;
      if (keyA > keyB) return direction === 'asc' ? 1 : -1;
      return 0;
    });

    return items;
  };

  const localeAdded = () => {
    setShowModal(false);
    retrieveLocali();
  };

  const handleTableItemClicked = async (item) => {
    await LocaliApi.getLocaleMetrics(item.id);
    setSelectedItem(item);
    setShowDetailModal(true);
  };

  const handleLockContract = async (date) => {
    const currentDate = Date.now()
    const { formattedDate } = getTodayDate();
    setContractEndDate(formattedDate);

    const startDateintoTimestamp = new Date(selectedItem.contract_starting_date).getTime();
    const passedDateDays = Math.round((currentDate - startDateintoTimestamp) / (1000 * 60 * 60 * 24)) - 1;

    const contractDays = {
      contract_starting_date: startDateintoTimestamp,
      contract_duration_days: passedDateDays
    };

    const { statusCode } = await LocaliApi.updateLocaliDates(selectedItem.id, contractDays);

    if (statusCode === 200) {
      retrieveLocali(date);
      setShowDetailModal(false);
    }
  };

  const handleDownloadMetrics = async () => {
    await LocaliApi.downloadLocaliMetrics();
  };

  useEffect(() => {
    retrieveLocali();
  }, [isInactive]);

  return (
    <div>
      <BaseModal showModal={showModal} closeModal={() => setShowModal(false)}>
        <AddLocale closeModal={localeAdded} />
      </BaseModal>
      <BaseModal showModal={showDetailModal} closeModal={() => setShowDetailModal(false)}>
        <LocaleDetails locale={selectedItem} lockLocalContract={handleLockContract} />
      </BaseModal>
      <ListHeader
        onDownloadMetrics={() => handleDownloadMetrics()}
        onNameFilterChange={handleNameFilterChange}
        onMainButtonClick={() => setShowModal(true)}
        placeholder='Cerca locale'
        title='Locali registrati:'
      />
      <Separator space={60} />
      <BaseTable
        isLocal
        headers={headers}
        items={filterItems(items)}
        onItemClick={handleTableItemClicked}
      />
    </div>
  );
};

export default Locali;
