import React from 'react';
import PropType from 'prop-types';

// Components
import BaseCheckbox from '../../base/BaseCheckbox/BaseCheckbox';
import BaseLabel from '../../base/BaseLabel/BaseLabel';
import Separator from '../../base/Separator/Separator';

const ActivePlaceFilter = ({ label, value, onChange }) => {
  return (
    <div>
      <BaseLabel label='Status' />
      <Separator space={20} />
      <BaseCheckbox
        label={label ? label : 'Mostra locali non attivi'}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};
ActivePlaceFilter.propTypes = {
  label: PropType.string,
  onChange: PropType.func,
  value: PropType.bool,
};
export default ActivePlaceFilter;
