import React, { useContext, useEffect, useState } from 'react';

import DashboardFilters from '../../components/filters/DashboardFilters/DashboardFilters';
import PageLayout from '../../components/layout/Navbar/PageLayout/PageLayout';
import { FilterContext, LOCALI } from '../../context/filter-context';

import Customers from '../Customers/Customers';
import Locali from '../Locali/Locali';

const Dashboard = () => {
  const [listView, setListView] = useState();

  const filterContext = useContext(FilterContext);
  const { listType } = filterContext;

  useEffect(() => {
    const listView = listType === LOCALI ? <Locali /> : <Customers />;
    setListView(listView);
  }, [listType]);

  return <PageLayout filters={<DashboardFilters />} list={listView}></PageLayout>;
};

export default Dashboard;
