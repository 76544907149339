import React from 'react';
import PropType from 'prop-types';

// Utils
import { formatDate } from '../../../utils/dates';

// Components
import Separator from '../../base/Separator/Separator';
import BaseButton from '../../base/BaseButton/BaseButton';

// Styles
import styles from './CustomerDetails.module.scss';
import classes from '../../base/BaseTable/BaseTable.module.scss';

const CustomerDetails = ({ customer, handleCustomerStatus }) => {
  return (
    <div className={styles.customerDetails}>
      <div className={styles.customerDetails__title}>
        <h1>{customer.firstname}</h1>
        <div
          className={`${styles.customerDetails__status} ${
            customer.status === 'none' ? classes.table__active : classes.table__none
          }`}
        ></div>
      </div>
      <a
      // herf={`mailto:${customer.email}`}
      >
        {customer.email}
      </a>

      <Separator space={40} />

      <p>Iscrizione: {formatDate(customer.creation_date)}</p>
      <BaseButton
        icon={customer.status === 'none' ? 'blocked' : 'light'}
        label={customer.status === 'none' ? 'Blocca utente' : 'Attiva utente'}
        type='text'
        onClick={handleCustomerStatus}
        className={
          customer.status === 'none'
            ? `${styles.customerDetails__button_red}`
            : `${styles.customerDetails__button_green}`
        }
      />
      <hr />
      <p>
        Likes inviati: <strong>{customer.likes_sent_number}</strong>
      </p>
      <p>
        Likes ricevuti: <strong>{customer.likes_received_number}</strong>
      </p>
      <p>
        Matches avvenuti: <strong>{customer.matches_number}</strong>
      </p>
    </div>
  );
};

CustomerDetails.propTypes = {
  customer: PropType.object,
  handleCustomerStatus: PropType.func,
};

export default CustomerDetails;
