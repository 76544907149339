import React, { useContext } from 'react';
import PropType from 'prop-types';

import ButtonFilter from '../../base/ButtonFilter/ButtonFilter';
import BaseLabel from '../../base/BaseLabel/BaseLabel';

import styles from './ListFilter.module.scss';

import { FilterContext, LOCALI, UTENTI } from '../../../context/filter-context';

const ListFilter = () => {
  const { listType, setListType } = useContext(FilterContext);

  const buttons = [
    {
      label: 'Locali',
      value: LOCALI,
    },
    {
      label: 'Utenti',
      value: UTENTI,
    },
  ];

  return (
    <div>
      <BaseLabel label='Visualizza lista' />
      <div className={styles.buttons}>
        {buttons.map((button) => {
          return (
            <ButtonFilter
              isActive={listType === button.value}
              label={button.label}
              key={button.value}
              onClick={() => setListType(button.value)}
            />
          );
        })}
      </div>
    </div>
  );
};

ListFilter.propTypes = {
  handleListFilterUpdate: PropType.func,
};

export default ListFilter;
