import React from 'react';
import PropType from 'prop-types';

import styles from './BaseButton.module.scss';

// Icons
import { ReactComponent as IconDownload } from '../../../assets/icons/iconLineDownload.svg';
import { ReactComponent as Plus } from '../../../assets/icons/plus.svg';
import { ReactComponent as Blocked } from '../../../assets/icons/blocked.svg';
import { ReactComponent as Light } from '../../../assets/icons/light.svg';

/**
 * Base component for buttons
 * @param {String} className - Used to pass additionals classes
 * @param {String} icon - Used to pass a prepend icon
 * @param {String} label - Used to pass the label to be shown into the button
 * @param {Function} onClick - Used to the handler for the click action
 * @param {String} type - Used to the type of the button, based on that the style will be different
 * @returns
 */
const BaseButton = ({ className, icon, label, onClick, type = 'default', disabled = false }) => {
  const icons = {
    download: <IconDownload height={18} width={18} />,
    plus: <Plus height={10} width={10} />,
    blocked: <Blocked height={24} width={24} />,
    light: <Light height={24} width={24} />,
  };

  return (
    <button className={`${styles.wrapper} ${className}`} data-type={type} onClick={onClick} disabled={disabled}>
      {icon ? icons[icon] : ''}
      {label}
    </button>
  );
};

BaseButton.propTypes = {
  className: PropType.string,
  icon: PropType.string,
  label: PropType.string,
  onClick: PropType.func,
  type: PropType.string,
  disabled: PropType.bool
};

export default BaseButton;
