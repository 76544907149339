import React from 'react';
import PropType from 'prop-types';

// Components
import BaseButton from '../../base/BaseButton/BaseButton';

// Utils
import { formatDate } from '../../../utils/dates';

// Syles
import styles from './LocaleDetails.module.scss';

/**
 * Comonent used to sho the local Details
 * @param {*} locale - object of selected element
 * @param {*} lockLocalContract - function to blocked the contract
 * @returns
 */

const LocaliDetails = ({ locale, lockLocalContract }) => {
  return (
    <div className={styles.localeDetails}>
      <div className={styles.localeDetails__header}>
        <h1>{locale.name}</h1>
        <span>
          <div
            className={styles.localeDetails__status}
            style={{
              backgroundColor: locale.status === 'active' ? '#569235' : '#c43535',
            }}
          ></div>
        </span>
      </div>
      <p className={styles.localeDetails__email}>{locale.email}</p>
      <p>{'Inizio contratto:' + ' ' + formatDate(locale.contract_starting_date)}</p>
      <div className={styles.localeDetails__content}>
        <p>{'Fine contratto:' + ' ' + locale.contract_ending_date}</p>
        {locale.status === 'active' && (
          <BaseButton
            icon='blocked'
            label='Blocca contratto'
            type='text'
            onClick={lockLocalContract}
            className={styles.localeDetails__button}
          />
        )}
      </div>

      <hr />
      <p className={styles.localeDetails__text}>Qr code generati: {locale.qr_codes_number}</p>
      <p className={styles.localeDetails__text}>Utenti registrati: {locale.checkins_number}</p>
      <p className={styles.localeDetails__text}>{'Ads attivi:' + ' ' + locale.active_ads + '/5'}</p>
    </div>
  );
};

LocaliDetails.propTypes = {
  locale: PropType.object,
  lockLocalContract: PropType.func,
};

export default LocaliDetails;
