import { createContext, useState } from 'react';
import PropType from 'prop-types';

export const LOCALI = 'LOCALI';
export const UTENTI = 'UTENTI';

export const FilterContext = createContext();

const filter = () => {
  const [age, setAge] = useState([]);
  const [showBlocked, setShowBlocked] = useState(false);
  const [dates, setDates] = useState();
  const [interessi, setInteressi] = useState();
  const [orientamento, setOrientamento] = useState();
  const [profili, setProfili] = useState();
  const [sesso, setSesso] = useState();
  const [listType, setListType] = useState(LOCALI);
  const [localiSort, setLocaliSort] = useState({ key: 'number_of_checkins', direction: 'desc' });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isInactive, setIsInactive] = useState(false);
  const [contractEndDate, setContractEndDate] = useState(null);

  return {
    age,
    contractEndDate,
    dates,
    endDate,
    interessi,
    isInactive,
    listType,
    localiSort,
    orientamento,
    profili,
    sesso,
    showBlocked,
    startDate,
    setAge,
    setContractEndDate,
    setDates,
    setEndDate,
    setInteressi,
    setIsInactive,
    setListType,
    setLocaliSort,
    setOrientamento,
    setProfili,
    setSesso,
    setShowBlocked,
    setStartDate,
  };
};

export const FilterContextProvider = ({ children }) => {
  return <FilterContext.Provider value={filter()}>{children}</FilterContext.Provider>;
};

FilterContextProvider.propTypes = {
  children: PropType.any,
};
