import React from 'react';
import PropType from 'prop-types';

import styles from './AgeFilter.module.scss';

import Slider from 'rc-slider';

import 'rc-slider/assets/index.css';

const AgeFilter = ({ setAgeFilter }) => {
  return (
    <div className={styles.slider}>
      <Slider
        defaultValue={[18, 100]}
        handleRender={(renderProps) => {
          return (
            <div {...renderProps.props}>
              <span className={styles.slider__handlerNumber}>
                {renderProps.props['aria-valuenow']}
              </span>
            </div>
          );
        }}
        range
        handleStyle={{
          borderColor: '#e7bf62',
          ':active': {},
        }}
        trackStyle={{
          backgroundColor: '#e7bf62',
        }}
        min={18}
        max={100}
        onChange={(event) => setAgeFilter(event)}
      />
    </div>
  );
};

AgeFilter.propTypes = {
  setAgeFilter: PropType.func,
  value: PropType.array,
};

export default AgeFilter;
