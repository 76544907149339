import React, { useContext } from 'react';
import { AuthContext } from '../../../context/auth-context';

import styles from './Navbar.module.scss';
import { ReactComponent as LogoutIcon } from '../../../assets/icons/iconLineLogoutS.svg';
import logo from '../../../assets/images/logo.svg';

const Navbar = () => {
  const { logout } = useContext(AuthContext);

  const handleLogout = () => {
    logout();
  };

  return (
    <header className={styles.wrapper}>
      <div className={styles.wrapper__logoContainer}>
        <img src={logo} />
        <p className={styles.wrapper__title}>WAGWAN</p>
      </div>

      <div className={styles.wrapper__logout} onClick={handleLogout}>
        <p className={styles.wrapper__logoutLabel}>Logout</p>
        <LogoutIcon />
      </div>
    </header>
  );
};

export default Navbar;
