import React, { useContext } from 'react';
import PropType from 'prop-types';

import DatePicker, { registerLocale } from 'react-datepicker';
import it from 'date-fns/locale/it';
registerLocale('it', it);

import 'react-datepicker/dist/react-datepicker.css';

import { FilterContext } from '../../../context/filter-context';

import { ReactComponent as IconLeftArrow } from '../../../assets/icons/iconLineArrowLeft.svg';
import { ReactComponent as IconRightArrow } from '../../../assets/icons/iconLineArrowRight.svg';

import inputStyle from '../BaseInput/BaseInput.module.scss';
import styles from './BaseCalendar.module.scss';

/**
 * Base component to show a calendar
 * @param {Boolean} inline - Define if showing or not the input
 * @param {Function} onChange - Used to handle the change date event
 * @param {Boolean} range - Used to define if component should handle a range of dates or a single one
 * @returns
 */
const BaseCalendar = ({ inline = true, onChange, range = true }) => {
  const filterContext = useContext(FilterContext);
  const { startDate, endDate, setStartDate, setEndDate } = filterContext;

  const onChangeDates = (dates) => {
    if (range) {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);

      if (start && end) {
        onChange([start, end]);
      }
    }

    if (!range) {
      setStartDate(dates);
      onChange(dates);
    }
  };

  const getYear = (date) => {
    return date.getFullYear();
  };

  const getMonth = (date) => {
    const months = [
      'Gennaio',
      'Febbraio',
      'Marzo',
      'Aprile',
      'Maggio',
      'Giugno',
      'Luglio',
      'Agosto',
      'Settembre',
      'Ottobre',
      'Novembre',
      'Dicembre',
    ];
    return months[date.getMonth()];
  };

  const formatWeekDay = () => {
    // return day.slice(0, 1).toUpperCase();
  };

  return (
    <DatePicker
      className={inputStyle.wrapper}
      dateFormat='dd/MM/yyyy'
      utcOffset={0}
      inline={inline}
      locale='it'
      selected={startDate}
      startDate={startDate}
      endDate={endDate}
      formatWeekDay={formatWeekDay}
      onChange={onChangeDates}
      onChangeRaw={(e) => {
        if (e.target.value) {
          const dates = e.target.value.split(' - ').map((date) => {
            const [day, month, year] = date.split('/');
            const d = new Date(`${year}/${month}/${day}`);
            return d;
          });
          onChangeDates(dates);
        }
      }}
      selectsRange={range}
      calendarClassName={styles.calendar}
      renderCustomHeader={({ date, decreaseMonth, increaseMonth, decreaseYear, increaseYear }) => (
        <div className={styles.header}>
          <div className={styles['header-navigation']}>
            <IconLeftArrow onClick={decreaseMonth} />
            <p>{getMonth(date)} </p>
            <IconRightArrow onClick={increaseMonth} />
          </div>

          <div className={styles['header-navigation']}>
            <IconLeftArrow onClick={decreaseYear} />
            <p>{getYear(date)} </p>
            <IconRightArrow onClick={increaseYear} />
          </div>
        </div>
      )}
    />
  );
};

BaseCalendar.propTypes = {
  inline: PropType.bool,
  onChange: PropType.func,
  range: PropType.bool,
};

export default BaseCalendar;
