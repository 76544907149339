import React, { useContext } from 'react';
import { FilterContext, LOCALI } from '../../../context/filter-context';
import CustomerFilters from '../CustomerFilters/CustomerFilters';

import ListFilter from '../list/ListFilter';
import PlacesFilters from '../PlacesFilters/PlacesFilters';

import styles from './DashboardFilters.module.scss';

const DashboardFilters = () => {
  const filterContext = useContext(FilterContext);
  const { listType } = filterContext;

  return (
    <div className={styles.wrapper}>
      <ListFilter handleListFilterUpdate={() => {}}></ListFilter>
      {listType === LOCALI ? <PlacesFilters /> : <CustomerFilters />}
    </div>
  );
};

export default DashboardFilters;
