import React from 'react';
import PropType from 'prop-types';

import BaseCheckbox from '../../base/BaseCheckbox/BaseCheckbox';

import styles from './CheckboxesFilter.module.scss';

const CheckboxesFilter = ({ items = [], selected = [], onSelectionChange, title }) => {
  const handleOnChange = (value) => {
    const elementIndex = selected.findIndex((el) => el === value);

    if (elementIndex === -1) {
      onSelectionChange(selected.concat(value));
      return;
    }
    if (elementIndex !== -1) {
      const selectedCopy = [...selected];
      selectedCopy.splice(elementIndex, 1);

      onSelectionChange(selectedCopy);
    }
  };

  return (
    <div className={styles.wrapper}>
      <p>{title}:</p>
      {items.map((option, index) => {
        return (
          <BaseCheckbox
            key={index}
            label={option.label}
            onChange={() => handleOnChange(option.value)}
            value={selected.includes(option.value)}
          />
        );
      })}
    </div>
  );
};

CheckboxesFilter.propTypes = {
  items: PropType.array,
  onSelectionChange: PropType.func,
  selected: PropType.array,
  title: PropType.string,
};

export default CheckboxesFilter;
