import { useContext } from 'react';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import { getCookie, setCookie } from '../../../utils/cookies';
import { AuthContext } from '../../../context/auth-context';

const GuardRoute = ({ element }) => {
  const [params] = useSearchParams();
  const bearerToken = params.get('__bearer_access_token');
  const { isAuthenticated } = useContext(AuthContext);

  const jwtToken = getCookie('jwt');

  if (isAuthenticated || jwtToken || bearerToken) {
    if (!jwtToken) setCookie({ cookieName: 'jwt', cookieValue: bearerToken });
    return element;
  }

  if (!isAuthenticated || (!jwtToken && !bearerToken)) {
    window.location.replace(
      `${process.env.REACT_APP_BASE_URL}/login?__redirect_uri=${process.env.REACT_APP_FE_URL}/`,
    );
  }

  return null;
};

GuardRoute.propTypes = {
  children: PropTypes.any,
  element: PropTypes.any,
  path: PropTypes.string,
};

export default GuardRoute;
