import { Route, Routes } from 'react-router-dom';

// Route view
import HomePage from './view/Home/HomePage';

// Styles
import './styles/styles.scss';
import GuardRoute from './components/base/GuardRoute/GuardRoute';

function App() {
  return (
    <div className='App'>
      <Routes re>
        <Route path='/' element={<GuardRoute element={<HomePage />} />} />
      </Routes>
    </div>
  );
}

export default App;
