import React, { useEffect, useRef, useState } from 'react';
import PropType from 'prop-types';

import styles from './BaseSelect.module.scss';

import { ReactComponent as IconArrowLeft } from '../../../assets/icons/iconLineArrowLeft.svg';

/**
 * Base select component to pick between different options
 * @param {Function} onOptionChange - Callback called when clicking one of the options
 * @param {Array} options - List of options to select from
 * @returns
 */
const BaseSelect = ({ onOptionChange, options = [] }) => {
  const [isToggled, setIsToggled] = useState(false);
  const [selectWidth, setSelectWidth] = useState();
  const [selectedOption, setSelectedOption] = useState(() => options.length > 0 && options[0]);

  const selectRef = useRef();

  useEffect(() => {
    const { width } = selectRef.current.getBoundingClientRect();
    setSelectWidth(width);
  }, [selectRef]);

  useEffect(() => {
    onOptionChange(selectedOption);
  }, [selectedOption]);

  return (
    <div
      className={`${styles.select} ${isToggled && styles['select--is-opened']}`}
      ref={selectRef}
      onClick={() => setIsToggled((prev) => !prev)}
    >
      <span>{selectedOption && selectedOption.label}</span>
      <IconArrowLeft />
      <div className={styles.select__options} style={{ width: `${selectWidth}px` }}>
        {options.map((option) => {
          return (
            <span
              className={styles['select__options__option']}
              key={option.value}
              onClick={() => setSelectedOption(option)}
            >
              {option.label}
            </span>
          );
        })}
      </div>
    </div>
  );
};

BaseSelect.propTypes = {
  onOptionChange: PropType.func,
  options: PropType.array,
};

export default BaseSelect;
