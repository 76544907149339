import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getCookie, setCookie, removeCookie } from '../utils/cookies';

const AuthContext = React.createContext({
  isAuthenticated: false,
  login: () => {},
  logout: () => {},
});

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const jwtToken = getCookie('jwt');
    if (jwtToken) {
      setIsAuthenticated(true);
    }
  }, []);

  const login = (jwtToken) => {
    setCookie('jwt', jwtToken);
    setIsAuthenticated(true);
  };

  const logout = () => {
    removeCookie('jwt');
    setIsAuthenticated(false);
    setCookie('jwt', '');
    window.location.replace(
      `${process.env.REACT_APP_BASE_URL}/login?__redirect_uri=${process.env.REACT_APP_FE_URL}/`,
    );
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
AuthProvider.propTypes = {
  children: PropTypes.any,
};
export { AuthContext, AuthProvider };
