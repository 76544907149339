import React, { useContext, useEffect, useState } from 'react';

// API
import CustomerApi from '../../api/modules/customer';

// Components
import BaseModal from '../../components/base/BaseModal/BaseModal';
import BaseTable from '../../components/base/BaseTable/BaseTable';
import CustomerDetails from '../../components/modals/CustomerDetails/CustomerDetails';
import ListHeader from '../../components/base/ListHeader/ListHeader';
import Separator from '../../components/base/Separator/Separator';

// Context
import { FilterContext } from '../../context/filter-context';

// Utils
import { interests } from '../../utils/interests';

const Customers = () => {
  const [items, setItems] = useState([]);
  const [nameFilter, setNameFilter] = useState();
  const [params, setParams] = useState();
  const [selectedItem, setSelectedItem] = useState();
  const [showDetailModal, setShowDetailModal] = useState();

  const filterContext = useContext(FilterContext);
  const { age, dates, interessi, orientamento, profili, sesso, showBlocked } = filterContext;

  const headers = [
    {
      label: 'Utente',
      valueKey: 'firstname',
      style: {
        flex: '1',
        maxWidth: '286px',
        width: '286px',
      },
    },
    {
      label: 'Età',
      valueKey: 'birthdate',
      formatData: (data) => {
        return new Date().getFullYear() - new Date(data).getFullYear();
      },
      style: {
        maxWidth: '26px',
        width: '26px',
      },
    },
    {
      label: 'Sesso',
      valueKey: 'gender',
      formatData: (data) => {
        return data.substring(0, 1);
      },
      style: {
        textTransform: 'uppercase',
        width: '100px',
      },
    },
    {
      label: 'Profili mostrati',
      valueKey: 'sexual_orientation',
      style: {
        width: '160px',
      },
    },
    {
      label: 'Orientamento',
      valueKey: 'interested_in',
      style: {
        width: '60px',
      },
    },

    {
      label: 'Interessi',
      valueKey: 'interest_ids',
      formatData: (data) => {
        const _interests = interests.filter(({ value }) => data.includes(value)) || [];
        const labels = _interests.map(({ label }) => label);
        return labels.join().replace(/,/g, ', ');
      },
      style: {
        width: '400px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
    {
      label: 'Status',
      valueKey: 'status',
      style: {
        width: '150px',
        paddingRight: '75px',
      },
    },
  ];

  const filterItems = (items) => {
    if (nameFilter) {
      items = items.filter(
        ({ firstname }) => firstname.toLowerCase().indexOf(nameFilter.toLowerCase()) !== -1,
      );
    }

    return items;
  };

  const retrieveCustomers = async () => {
    let filteredCustomers = {
      filters: params,
      sorting: { field: 'id', direction: 'asc' },
    };

    const customerResource = await CustomerApi.retrieveCustomers(filteredCustomers);

    if (customerResource.statusCode === 200) {
      setItems(customerResource.data);
    }
  };

  const handleUpdateParams = ({
    age,
    interessi,
    orientamento,
    profili,
    sesso,
    dates,
    showBlocked,
  }) => {
    const tempParams = { ...params };

    tempParams.status = showBlocked ? ['blocked', 'none'] : ['none'];

    if (age && age.length) {
      let today = new Date();
      const from = new Date(today.setFullYear(today.getFullYear() - [age[1]]));
      today = new Date();
      const to = new Date(today.setFullYear(today.getFullYear() - [age[0]]));

      const birthdate = {
        from,
        to,
      };
      tempParams.birthdate = birthdate;
    } else {
      tempParams.birthdate = undefined;
    }
    if (dates && dates.length > 0) {
      const from = dates[0];
      const to = dates[1];
      const creationDate = { from: from, to: to };
      tempParams.creation_date = creationDate;
    }

    tempParams.gender = sesso && sesso.length > 0 ? sesso : undefined;
    tempParams.interests = interessi && interessi.length > 0 ? interessi : undefined;
    tempParams.interested_in = profili && profili.length > 0 ? profili : undefined;
    tempParams.sexual_orientation =
      orientamento && orientamento.length > 0 ? orientamento : undefined;
    setParams(tempParams);
  };

  useEffect(() => {
    if (!age && !interessi && !orientamento && !profili && !sesso && !showBlocked) return;

    handleUpdateParams({ age, dates, interessi, orientamento, profili, sesso, showBlocked });
  }, [age, interessi, orientamento, profili, sesso, dates, showBlocked]);

  useEffect(() => {
    if (params) retrieveCustomers();
  }, [params]);

  const handleTableItemClicked = (item) => {
    setSelectedItem(item);
    setShowDetailModal(true);
  };

  const handleDownloadMetrics = async () => {
    await CustomerApi.downloadCustumerMetrics();
  };

  const handleCustomerStatus = async () => {
    await CustomerApi.changeStatus(selectedItem.id, {
      new_status: selectedItem.status === 'none' ? 'blocked' : 'none',
      affected_role: 'customer',
    });
    setShowDetailModal(false);
    retrieveCustomers();
  };

  return (
    <div>
      <BaseModal showModal={showDetailModal} closeModal={() => setShowDetailModal(false)}>
        <CustomerDetails customer={selectedItem} handleCustomerStatus={handleCustomerStatus} />
      </BaseModal>
      <ListHeader
        onDownloadMetrics={handleDownloadMetrics}
        onNameFilterChange={(value) => setNameFilter(value)}
        placeholder='Cerca utente'
        title='Utenti registrati:'
        showAddLocale={false}
      />
      <Separator space={60} />
      <BaseTable
        headers={headers}
        items={filterItems(items)}
        onItemClick={handleTableItemClicked}
      />
    </div>
  );
};

export default Customers;
