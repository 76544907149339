import React from 'react';
import PropType from 'prop-types';

import styles from './ButtonFilter.module.scss';

const ButtonFilter = ({ isActive, label, onClick }) => {
  return (
    <button
      className={`${styles.button} ${isActive && styles['button--is-active']}`}
      onClick={onClick}
    >
      {label}
    </button>
  );
};

ButtonFilter.propTypes = {
  isActive: PropType.bool,
  label: PropType.string,
  onClick: PropType.func,
};

export default ButtonFilter;
