// Step Profilo
export const profilesOptions = [
  {
    value: 'male',
    label: 'Solo Uomini',
  },
  {
    value: 'female',
    label: 'Solo Donne',
  },
  {
    value: 'all',
    label: 'Tutti',
  },
];

// Step Orientamento

export const orientationOptions = [
  {
    value: 'heterosexual',
    label: 'Etero',
  },
  {
    value: 'gay',
    label: 'Gay',
  },
  {
    value: 'lesbian',
    label: 'Lesbica',
  },
  {
    value: 'bisexual',
    label: 'Bisex',
  },
  {
    value: 'asexual',
    label: 'Asessuale',
  },
  {
    value: 'demisexual',
    label: 'Demisessuale',
  },
  {
    value: 'pansexual',
    label: 'Pansessuale',
  },
  {
    value: 'queer',
    label: 'Queer',
  },
  {
    value: 'uncertain',
    label: 'Incerto/a',
  },
];

// Step sex
export const genderOptions = [
  {
    value: 'male',
    label: 'Uomo',
  },
  {
    value: 'female',
    label: 'Donna',
  },
  {
    value: 'nonbinary',
    label: 'Non binario',
  },
  {
    value: 'other',
    label: 'Altro',
  },
];
