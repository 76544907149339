import React, { useContext, useEffect, useState } from 'react';
import { FilterContext } from '../../../context/filter-context';
import { toISOLocal } from '../../../utils/dates';

import BaseCalendar from '../../base/BaseCalendar/BaseCalendar';
import BaseInput from '../../base/BaseInput/BaseInput';
import BaseLabel from '../../base/BaseLabel/BaseLabel';

import styles from './CalendarFilter.module.scss';

const CalendarFilter = () => {
  const [isDates, setIsDates] = useState(false);
  const [datesRange, setDatesRange] = useState();
  const [inputValue, setInputValue] = useState('');

  const filterContext = useContext(FilterContext);
  const { setDates, setEndDate, setStartDate } = filterContext;

  useEffect(() => {
    const formatDate = (date) => {
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    };

    if (datesRange && datesRange.length > 0) {
      const [from, to] = datesRange;

      const formattedFrom = formatDate(from);
      const formattedTo = formatDate(to);

      setInputValue(`${formattedFrom} - ${formattedTo}`);

      setDates([toISOLocal(from), toISOLocal(to)]);
      setIsDates(true);
    }
  }, [datesRange]);

  const removeDates = () => {
    setInputValue('');
    setDatesRange([]);
    setDates([]);
    setIsDates(false);
    setStartDate(null);
    setEndDate(null);
  };
  const handleOnChange = (event) => {
    setDatesRange(event);
  };

  return (
    <div>
      <BaseLabel label='Periodo' />
      <BaseInput
        dateSelected={isDates}
        className={styles.calendar}
        placeholder='Seleziona date'
        value={inputValue}
        readonly
        removeDates={removeDates}
      />
      <BaseCalendar onChange={handleOnChange} />
    </div>
  );
};

export default CalendarFilter;
