import React, { useEffect, useState } from 'react';
import PropType from 'prop-types';

import BaseInput from '../../base/BaseInput/BaseInput';
import BaseLabel from '../../base/BaseLabel/BaseLabel';
import BaseSelect from '../../base/BaseSelect/BaseSelect';
import Separator from '../../base/Separator/Separator';

import styles from './AgreementLengthPicker.module.scss';

import { DAYS, MONTHS, YEARS } from '../../../utils/contstants';

const AgreementLengthPicker = ({ setAgreementTime }) => {
  const [time, setTime] = useState(1);
  const [timeUnit, setTimeUnit] = useState();

  const options = [
    {
      label: 'Anni',
      value: YEARS,
    },
    {
      label: 'Mesi',
      value: MONTHS,
    },
    {
      label: 'Giorni',
      value: DAYS,
    },
  ];

  useEffect(() => {
    if (time && timeUnit) setAgreementTime({ time: Number(time), timeUnit: timeUnit.value });
  }, [time, timeUnit]);

  return (
    <div>
      <BaseLabel label='Durata contratto' />
      <div className={styles.selection}>
        <BaseInput
          value={time}
          className={styles.input}
          placeholder='1'
          onChange={(time) => setTime(time)}
        />
        <Separator direction='horizontal' space={10} />
        <BaseSelect options={options} onOptionChange={(option) => setTimeUnit(option)} />
      </div>
    </div>
  );
};

AgreementLengthPicker.propTypes = {
  setAgreementTime: PropType.func,
};

export default AgreementLengthPicker;
