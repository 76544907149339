import React from 'react';

import styles from './FilterToggle.module.scss';
import PropType from 'prop-types';

import { ReactComponent as CrossIcon } from '../../../assets/icons/iconLineCross.svg';

const FilterToggle = ({
  items,
  labels,
  removeItem,
  setItem,
  setSelectedFilter,
  showSingleTag,
  title,
}) => {
  return (
    <div className={styles.filterWrapper}>
      <div className={styles.filterWrapper__header}>
        <p>{title}</p>
        <p onClick={setSelectedFilter}>Aggiungi filtro</p>
      </div>
      {items && items.length > 0 && (
        <div className={styles.tagsWrapper}>
          {showSingleTag ? (
            <div className={styles.tag}>
              {items.join('-')}
              <CrossIcon onClick={() => setItem()} />
            </div>
          ) : (
            items.map((item) => {
              return (
                <div key={item} className={styles.tag}>
                  {labels ? labels[item] : item}
                  <CrossIcon onClick={() => removeItem(item)} />
                </div>
              );
            })
          )}
        </div>
      )}
    </div>
  );
};

FilterToggle.propTypes = {
  items: PropType.array,
  labels: PropType.object,
  removeItem: PropType.func,
  setItem: PropType.func,
  setSelectedFilter: PropType.func,
  showSingleTag: PropType.bool,
  title: PropType.string,
};

export default FilterToggle;
