import React, { useContext } from 'react';

// Componets
import ActivePlaceFilter from '../ActivePlaceFilter/ActivePlaceFilter';
import CalendarFilter from '../CalendarFilter/CalendarFilter';
import Separator from '../../base/Separator/Separator';

// Context
import { FilterContext } from '../../../context/filter-context';

// Style
import styles from './PlacesFilters.module.scss';

const PlacesFilters = () => {
  const filterContext = useContext(FilterContext);
  const { isInactive, setIsInactive } = filterContext;
  return (
    <div className={styles.wrapper}>
      <CalendarFilter />
      <Separator space={30} />
      <ActivePlaceFilter value={isInactive} onChange={() => setIsInactive(!isInactive)} />
    </div>
  );
};

export default PlacesFilters;
