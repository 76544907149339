import React from 'react';
import Navbar from '../Navbar';

import styles from './PageLayout.module.scss';

import PropTypes from 'prop-types';

const PageLayout = ({ filters, list }) => {
  return (
    <main className={styles.wrapper}>
      <Navbar />
      <section className={styles.content}>
        <section className={styles.filters}>{filters}</section>
        <section className={styles.list}>{list}</section>
      </section>
    </main>
  );
};

PageLayout.propTypes = {
  filters: PropTypes.element,
  list: PropTypes.element,
};

export default PageLayout;
