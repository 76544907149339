import { instance, downloadMetricsInstance } from '../index';

const CustomerApi = {
  retrieveCustomers(payload) {
    return instance.post('/customers', payload);
  },
  downloadCustumerMetrics() {
    return downloadMetricsInstance.post('/customers/download-customers-data', {});
  },
  changeStatus(id, payload) {
    return instance.post(`/users/action/change-status/${id}`, payload);
  },
};

export default CustomerApi;
